var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vans-template-pdp-component",class:{
    'vans-template-pdp-component--redesign': _vm.isVansPdpRedesignEnabled,
  }},[_c('VfContainer',{attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","medium-margins":{ bottom: 'md-no-margin-b' },"large-margins":{ bottom: 'lg-no-margin-b' }}},[_c('CMSBreadcrumbs',{attrs:{"translations":_vm.$t('breadcrumbs'),"context-key":_vm.contextKey}})],1)],1)],1),_vm._v(" "),_c('CMSDiscountNotification',{attrs:{"context-key":_vm.contextKey,"notification-persistent":_vm.$themeSettings.DiscountNotification.notificationPersistent,"translations":_vm.$t('discountNotification')}}),_vm._v(" "),_c('VfContainer',{attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[_c('client-only',[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","small-margins":{
            custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
          },"medium-margins":{
            custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
          },"large-margins":{
            custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
          }}},[(_vm.product)?_c('CMSPdpStickyHeader',{attrs:{"translations":_vm.$t('pdpStickyHeader'),"context-key":_vm.contextKey}}):_vm._e(),_vm._v(" "),_c('CMSBackToTopButton',{attrs:{"translations":_vm.$t('backToTopButton')}})],1)],1),_vm._v(" "),(_vm.isVansPdpRedesignEnabled)?_c('VfColumn',{attrs:{"small":"12","medium":"8","large":"8","hidden-on-medium":"","hidden-on-large":"","small-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '0 16px 16px 16px'
            : '0 10px 12px 10px',
        }}},[_c('CMSOneLineLabel',{attrs:{"custom-label":_vm.isVansPdpRedesignEnabled,"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),_c('CMSProductName',{attrs:{"context-key":_vm.contextKey,"display-badge-inside-title":_vm.$themeSettings.ProductName.displayBadgeInsideTitle,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),_c('CMSProductRating',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending,"show-review-ratio":_vm.$themeSettings.ProductRating.showReviewRatio,"translations":_vm.$t('productRating')}}),_vm._v(" "),_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"nested":"","small":"10","medium":"10","large":"10","small-margins":{
                top: 'sm-no-margin-t',
                bottom: 'sm-no-margin-b',
              },"medium-margins":{
                top: 'md-no-margin-t',
                bottom: 'md-no-margin-b',
              },"large-margins":{
                top: 'lg-no-margin-t',
                bottom: 'lg-no-margin-b',
              }}},[_c('CMSProductPrice',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}})],1),_vm._v(" "),_c('VfColumn',{attrs:{"nested":"","small":"2","medium":"2","large":"2","small-margins":{
                top: 'sm-no-margin-t',
                bottom: 'sm-no-margin-b',
              },"medium-margins":{
                top: 'md-no-margin-t',
                bottom: 'md-no-margin-b',
              },"large-margins":{
                top: 'lg-no-margin-t',
                bottom: 'lg-no-margin-b',
              },"content-position":{
                small: 'middle_right',
                medium: 'middle_right',
                large: 'middle_right',
              }}},[_c('CMSProductActionButtons',{attrs:{"context-key":_vm.contextKey,"translations":_vm.$t('productActionButtons'),"turn-on-add-to-favourites":_vm.$themeSettings.ProductActionButtons.pdp
                    .favoritesHeartSection.turnOnAddToFavourites,"turn-on-find-in-store":_vm.$themeSettings.ProductActionButtons.pdp
                    .favoritesHeartSection.turnOnFindInStore}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('VfColumn',{class:{ 'vans-pdp-column-gallery': _vm.isVansPdpRedesignEnabled },attrs:{"small":"12","medium":"8","large":"8","small-margins":{
          custom: _vm.isVansPdpRedesignEnabled ? '0 4px 16px 4px' : '12px 10px',
        },"medium-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '8px 16px 16px 16px'
            : '12px 10px',
        },"large-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '8px 16px 16px 16px'
            : '12px 10px',
        }}},[_c('CMSProductGallery',{attrs:{"context-key":_vm.contextKey,"sequence-number":_vm.$themeSettings.ProductGallery.sequenceNumber,"show-wish-list-icon":_vm.$themeSettings.ProductGallery.showWishListIcon,"translations":_vm.$t('productGallery')}}),_vm._v(" "),(!_vm.isVansPdpRedesignEnabled)?_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":"","hidden-on-medium":"","hidden-on-large":"","small-margins":{
                custom: '0 10px',
              },"medium-margins":{
                custom: '0 10px',
              },"large-margins":{
                custom: '0 10px',
              }}},[_c('CMSOneLineLabel',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),_c('CMSProductName',{attrs:{"context-key":_vm.contextKey,"display-badge-inside-title":_vm.$themeSettings.ProductName.displayBadgeInsideTitle,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),_c('CMSProductRating',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending,"show-review-ratio":_vm.$themeSettings.ProductRating.showReviewRatio,"translations":_vm.$t('productRating')}}),_vm._v(" "),_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"nested":"","small":"10","medium":"10","large":"10","small-margins":{
                      top: 'sm-no-margin-t',
                      bottom: 'sm-no-margin-b',
                    },"medium-margins":{
                      top: 'md-no-margin-t',
                      bottom: 'md-no-margin-b',
                    },"large-margins":{
                      top: 'lg-no-margin-t',
                      bottom: 'lg-no-margin-b',
                    }}},[_c('CMSProductPrice',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}})],1),_vm._v(" "),_c('VfColumn',{attrs:{"nested":"","small":"2","medium":"2","large":"2","small-margins":{
                      top: 'sm-no-margin-t',
                      bottom: 'sm-no-margin-b',
                    },"medium-margins":{
                      top: 'md-no-margin-t',
                      bottom: 'md-no-margin-b',
                    },"large-margins":{
                      top: 'lg-no-margin-t',
                      bottom: 'lg-no-margin-b',
                    },"content-position":{
                      small: 'middle_right',
                      medium: 'middle_right',
                      large: 'middle_right',
                    }}},[_c('CMSProductActionButtons',{attrs:{"context-key":_vm.contextKey,"translations":_vm.$t('productActionButtons'),"turn-on-add-to-favourites":_vm.$themeSettings.ProductActionButtons.pdp
                          .favoritesHeartSection.turnOnAddToFavourites,"turn-on-find-in-store":_vm.$themeSettings.ProductActionButtons.pdp
                          .favoritesHeartSection.turnOnFindInStore}})],1)],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.isVansPdpRedesignEnabled && _vm.$viewport.size !== 'small')?_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":"","hidden-on-small":"","medium-margins":{
                  custom: _vm.isVansPdpRedesignEnabled
                    ? '18px 16px 16px'
                    : '12px 10px',
                },"large-margins":{
                  custom: _vm.isVansPdpRedesignEnabled
                    ? '18px 16px 16px'
                    : '12px 10px',
                }}},[(!_vm.$fetchState.pending)?_c('CMSOlapic',{key:_vm.productColorsKey,attrs:{"context-key":_vm.contextKey},on:{"third-party-loaded":_vm.onThirdPartyLoaded}}):_vm._e()],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"4","large":"4","medium-margins":{
          left: _vm.isVansPdpRedesignEnabled ? 'md-no-margin-l' : '',
        },"large-margins":{
          left: _vm.isVansPdpRedesignEnabled ? 'lg-no-margin-l' : '',
        }}},[_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":"","hidden-on-small":"","medium-margins":{
                custom: _vm.isVansPdpRedesignEnabled ? '0 16px 16px' : '0 10px',
              },"large-margins":{
                custom: _vm.isVansPdpRedesignEnabled ? '0 16px 16px' : '0 10px',
              }}},[(_vm.showCustomsLogo)?_c('div',{staticClass:"vans-redesign-space-width-custom-logo"}):_vm._e(),_vm._v(" "),_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"10","large":"10","nested":"","medium-margins":{
                      top: 'md-no-margin-t',
                      bottom: '16px',
                    },"large-margins":{
                      top: 'lg-no-margin-t',
                      bottom: '16px',
                    }}},[_c('CMSOneLineLabel',{attrs:{"custom-label":_vm.isVansPdpRedesignEnabled,"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}})],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"2","large":"2","nested":"","medium-margins":{
                      top: 'md-no-margin-t',
                      bottom: 'md-no-margin-b',
                    },"large-margins":{
                      top: 'lg-no-margin-t',
                      bottom: 'lg-no-margin-b',
                    }}},[_c('CMSProductActionButtons',{attrs:{"context-key":_vm.contextKey,"translations":_vm.$t('productActionButtons'),"turn-on-add-to-favourites":_vm.$themeSettings.ProductActionButtons.pdp
                          .favoritesHeartSection.turnOnAddToFavourites,"turn-on-find-in-store":_vm.$themeSettings.ProductActionButtons.pdp
                          .favoritesHeartSection.turnOnFindInStore}})],1)],1)],1),_vm._v(" "),_c('CMSProductName',{attrs:{"context-key":_vm.contextKey,"display-badge-inside-title":_vm.$themeSettings.ProductName.displayBadgeInsideTitle,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),_c('CMSProductRating',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending,"show-review-ratio":_vm.$themeSettings.ProductRating.showReviewRatio,"translations":_vm.$t('productRating')}}),_vm._v(" "),_c('CMSProductPrice',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending}}),_vm._v(" "),(
                  !_vm.isVansPdpRedesignEnabled &&
                  _vm.klarnaEnabled &&
                  !_vm.$fetchState.pending
                )?_c('LazyCMSKlarnaIntegration',{attrs:{"translations":_vm.$t('klarnaIntegration'),"context-key":_vm.contextKey}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.product && _vm.product.width)?_c('CMSProductAdditionalSizes',{attrs:{"context-key":_vm.contextKey,"data-id":"additional-sizes-selector-on-static-pdp","role":"width","show-numeration":_vm.$themeSettings.ProductAdditionalSizes.showNumeration,"show-selected-variation-title":_vm.$themeSettings.ProductAdditionalSizes.showSelectedVariationTitle,"show-top-divider":_vm.$themeSettings.ProductAdditionalSizes.showTopDivider,"preselect-default-size":_vm.$themeSettings.ProductAdditionalSizes.preselectDefaultSize,"translations":_vm.$t('productAdditionalSizes')}}):_vm._e(),_vm._v(" "),(
            _vm.checkProductAndStates(_vm.$themeSettings.PdpProductColors._states)
          )?_c('CMSPdpProductColors',{key:_vm.productColorsKey,attrs:{"context-key":_vm.contextKey,"data-id":"colors-selector-on-static-pdp","is-loading":_vm.$fetchState.pending,"items-count-large":_vm.$themeSettings.PdpProductColors.itemsCountLarge,"items-count-medium":_vm.$themeSettings.PdpProductColors.itemsCountMedium,"items-count-small":_vm.$themeSettings.PdpProductColors.itemsCountSmall,"show-numeration":_vm.$themeSettings.PdpProductColors.showNumeration,"show-always-all-colors":_vm.$themeSettings.PdpProductColors.showAlwaysAllColors,"translations":_vm.$t('productColors')}}):_vm._e(),_vm._v(" "),(
            !_vm.$fetchState.pending &&
            _vm.checkProductAndStates(_vm.$themeSettings.ProductCustomize._states)
          )?_c('CMSProductCustomize',{attrs:{"context-key":_vm.contextKey,"button-component":_vm.$themeSettings.ProductCustomize.buttonComponent,"button-size":_vm.$themeSettings.ProductCustomize.buttonSize,"button-style":_vm.$themeSettings.ProductCustomize.buttonStyle,"icon":_vm.$themeSettings.ProductCustomize.icon,"icon-position":_vm.$themeSettings.ProductCustomize.iconPosition,"text":_vm.$t('productCustomize.text')}}):_vm._e(),_vm._v(" "),_c('CMSProductSizes',{attrs:{"context-key":_vm.contextKey,"data-id":"sizes-selector-on-static-pdp","display-as":_vm.$themeSettings.ProductSizes.displayAs,"is-loading":_vm.$fetchState.pending || _vm.inventoryLoading,"preselect-default-size":_vm.$themeSettings.ProductSizes.preselectDefaultSize,"show-numeration":_vm.$themeSettings.ProductSizes.showNumeration,"show-sizes-expanded":_vm.$themeSettings.ProductSizes.showSizesExpanded,"translations":_vm.$t('productSizes')}}),_vm._v(" "),(
            !_vm.$fetchState.pending &&
            _vm.checkProductAndStates(
              _vm.$themeSettings.ProductQuantitySelect._states
            )
          )?_c('CMSProductQuantitySelect',{attrs:{"context-key":_vm.contextKey,"data-id":"quantity-selector-on-static-pdp","display-as":_vm.$themeSettings.ProductQuantitySelect.displayAs,"hide-component":!_vm.product.dummyCustoms,"show-numeration":_vm.isVansPdpRedesignEnabled
              ? false
              : _vm.$themeSettings.ProductQuantitySelect.showNumeration,"translations":_vm.$t('productQuantitySelect')}}):_vm._e(),_vm._v(" "),_c('CMSProductUpsell',{attrs:{"context-key":_vm.contextKey,"is-loading":_vm.$fetchState.pending || _vm.inventoryLoading,"translations":_vm.$t('productUpsell')}}),_vm._v(" "),(_vm.klarnaEnabled && !_vm.$fetchState.pending)?_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","hidden-on-large":!_vm.isVansPdpRedesignEnabled,"hidden-on-medium":!_vm.isVansPdpRedesignEnabled,"nested":""}},[_c('LazyCMSKlarnaIntegration',{attrs:{"translations":_vm.$t('klarnaIntegration'),"context-key":_vm.contextKey}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('VfContainer',{attrs:{"nested":""}},[_c('VfRow',{attrs:{"nested":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","hidden-on-large":"","nested":""}}),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":"","small-margins":{
                custom: _vm.isVansPdpRedesignEnabled
                  ? '16px 16px 0 16px'
                  : '12px 10px 0 10px',
              },"medium-margins":{
                custom: _vm.isVansPdpRedesignEnabled
                  ? '16px 16px 0 16px'
                  : '12px 10px 0 10px',
              },"large-margins":{
                custom: _vm.isVansPdpRedesignEnabled
                  ? '16px 16px 0 16px'
                  : '12px 10px 0 10px',
              }}},[_c('CMSProductAddToCart',{attrs:{"context-key":_vm.contextKey,"modals":_vm.addToCartBtnModalsProps,"show-add-to-favourites":!_vm.isVansPdpRedesignEnabled &&
                  _vm.$themeSettings.ProductAddToCart.showAddToFavourites,"translations":_vm.$t('productAddToCart')}}),_vm._v(" "),(_vm.allowApplePayOrderOnPDP)?_c('CMSApplePayPdp',{attrs:{"context-key":_vm.contextKey,"translations":_vm.$t('applePayPdp')}}):_vm._e()],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","nested":"","small-margins":{
                custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
              },"medium-margins":{
                custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
              },"large-margins":{
                custom: _vm.isVansPdpRedesignEnabled ? '0 16px' : '0 10px',
              }}},[_c('client-only',[(_vm.isBopis20Enabled && _vm.isShippingDestinationsVisible)?_c('VfShippingDestinations',{staticStyle:{"margin-bottom":"var(--spacer-base)","margin-top":"var(--spacer-base)"},attrs:{"context-key":_vm.contextKey}}):_vm._e()],1),_vm._v(" "),_c('CMSProductActionButtons',{attrs:{"context-key":_vm.contextKey,"translations":_vm.$t('productActionButtons'),"turn-on-add-to-favourites":_vm.$themeSettings.ProductActionButtons.pdp.findInStoreSection
                    .turnOnAddToFavourites,"turn-on-add-to-favourites-heart":_vm.$themeSettings.ProductActionButtons.pdp.findInStoreSection
                    .turnOnAddToFavouritesHeart}}),_vm._v(" "),(_vm.isVansPdpRedesignEnabled)?_c('div',{attrs:{"id":"vans-business-personalization"}}):_vm._e()],1),_vm._v(" "),(_vm.isVansPdpRedesignEnabled)?_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","small-margins":{
                custom: _vm.isVansPdpRedesignEnabled
                  ? '0 16px'
                  : '12px 10px 12px 10px',
              },"medium-margins":{
                top: _vm.isVansPdpRedesignEnabled ? 'md-no-margin-t' : '',
              },"large-margins":{
                top: _vm.isVansPdpRedesignEnabled ? 'lg-no-margin-t' : '',
              }}},[(_vm.product)?_c('CMSProductAdditionalDetails',{key:_vm.$route.path,attrs:{"translations":{
                  title: {
                    description: _vm.isVansPdpRedesignEnabled
                      ? _vm.$t('productDescription.productDetailsRedesign')
                      : _vm.$t('productDescription.productDetails'),
                  },
                },"types":['description']}}):_vm._e()],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('client-only',[(!_vm.isVansPdpRedesignEnabled)?_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[(!_vm.$fetchState.pending)?_c('CMSOlapic',{key:_vm.productColorsKey,attrs:{"context-key":_vm.contextKey},on:{"third-party-loaded":_vm.onThirdPartyLoaded}}):_vm._e()],1):_vm._e()],1),_vm._v(" "),(!_vm.isVansPdpRedesignEnabled)?_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('CMSProductDescription',{attrs:{"translations":_vm.$t('productDescription'),"context-key":_vm.contextKey}})],1):_vm._e()],1)],1),_vm._v(" "),_c('client-only',[(_vm.isVansPdpRedesignEnabled && _vm.$viewport.size === 'small')?_c('VfContainer',[_c('VfRow',[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","hidden-on-medium":"","hidden-on-large":"","small-margins":{
            custom: _vm.isVansPdpRedesignEnabled
              ? '24px 16px 8px 16px'
              : '12px 10px 12px 10px',
          }}},[(!_vm.$fetchState.pending)?_c('CMSOlapic',{key:_vm.productColorsKey,attrs:{"context-key":_vm.contextKey},on:{"third-party-loaded":_vm.onThirdPartyLoaded}}):_vm._e()],1)],1)],1):_vm._e()],1),_vm._v(" "),(_vm.dynamicSlots.belowGrid && _vm.dynamicSlots.belowGrid.children.length)?_c('CmsDynamicSlot',{key:_vm.$route.path,class:{ 'vans-pdp-dynamic-slot': _vm.isVansPdpRedesignEnabled },attrs:{"data":_vm.dynamicSlots.belowGrid,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowGrid.props.name}}):_vm._e(),_vm._v(" "),_c('client-only',[_c('VfContainer',{attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[(_vm.monetateExperiencesPdp.length)?_c('VfColumn',{key:_vm.$route.path,attrs:{"small":"12","medium":"12","large":"12","small-margins":{
            custom: _vm.isVansPdpRedesignEnabled
              ? '8px 16px 0 16px'
              : '12px 10px',
          },"medium-margins":{
            custom: _vm.isVansPdpRedesignEnabled
              ? '48px 16px'
              : '12px 10px 12px 10px',
          },"large-margins":{
            custom: _vm.isVansPdpRedesignEnabled
              ? '48px 16px'
              : '12px 10px 12px 10px',
          }}},_vm._l((_vm.monetateExperiencesPdp),function(item){return _c('CMSMonetateExperience',_vm._b({key:item.experienceId,class:_vm.areMonetateExperiencesSetInCmsForTemplatizedPages
                ? 'vans-pdp-monetate-experience-cms-driven-' +
                  item.experienceId
                : 'vans-pdp-monetate-experience-hc-driven-' +
                  item.experienceId,attrs:{"context-key":_vm.contextKey},on:{"third-party-loaded":_vm.onThirdPartyLoaded}},'CMSMonetateExperience',item,false))}),1):_vm._e()],1)],1),_vm._v(" "),_c('VfContainer',{attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[(_vm.checkProductAndStates([_vm.stateNotCustomsPDP]))?_c('CMSProductReviews',{key:_vm.$route.path,attrs:{"context-key":_vm.contextKey}}):_vm._e()],1)],1)],1)],1),_vm._v(" "),(_vm.seoWidgetRecommendationsEnabled && !_vm.$fetchState.pending)?_c('VfContainer',{attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12","small-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '24px 16px 16px 16px'
            : '12px 10px',
        },"medium-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '40px 16px 40px 16px'
            : '12px 10px',
        },"large-margins":{
          custom: _vm.isVansPdpRedesignEnabled
            ? '40px 16px 40px 16px'
            : '12px 10px',
        }}},[_c('LazyCMSSeoRelatedWidgetTextOnly',{attrs:{"context-key":_vm.contextKey}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('client-only',[(!_vm.isApp)?[_c(_vm.miniCartProps.component,_vm._b({key:_vm.$route.path,tag:"component"},'component',Object.assign({}, _vm.miniCartProps,
          {applePayTranslations: _vm.$t('miniCart.applePayTranslations'),
          translations: _vm.$t('miniCart.minicart')}),false)),_vm._v(" "),_c('CMSFloatingChatButton',{attrs:{"background-color":_vm.$themeSettings.FloatingChatButton.backgroundColor,"css-class":_vm.$themeSettings.FloatingChatButton.cssClass,"title":_vm.$themeSettings.FloatingChatButton.title,"title-color":_vm.$themeSettings.FloatingChatButton.titleColor}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }