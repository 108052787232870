















































































































































import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import { VueMaskDirective } from 'v-mask';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
  BasicInformationTranslations,
  BasicInformationObject,
  BasicInformationValidationMessages,
  FormValidationConfig,
} from '@vf/api-contract';
import {
  getDatePlaceholderFor,
  getDateMaskFor,
  translateFromApiDateFormat,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import {
  getCacheKeyFromProps,
  checkDate,
  checkName,
  checkAge,
  stripPhone,
  checkPhone,
} from '@vf/shared/src/utils/helpers';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { CountryCode } from 'libphonenumber-js';

/* TODO: Locale set to en-US to ensure correct birthday format for TNF fr-CA. Needs to be a helm-env variable. */
const BIRTHDAY_LOCALE = 'en-US';

export default defineComponent({
  name: 'BasicInformation',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<BasicInformationTranslations>,
      required: true,
    },
    basicInformation: {
      type: Object as PropType<BasicInformationObject>,
      default: () => ({}),
    },
    validationMessages: {
      type: Object as PropType<BasicInformationValidationMessages>,
      default: () => ({}),
    },
    validationConfig: {
      type: Object as PropType<FormValidationConfig>,
      default: () => ({}),
    },
    minRegisterAge: {
      type: Number,
      default: 13,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    subscribedToLoyalty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showEnrollmentFooter: {
      type: Boolean,
      default: false,
    },
    phoneInputCountries: {
      type: Array as PropType<PhoneInputCountry[]>,
      default: () => [],
    },
    phoneInputDefaultCountryCode: {
      type: String as PropType<CountryCode>,
      default: 'US',
    },
    showPhoneInputCountry: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const datePlaceholder = props.validationConfig?.birthdayFormat
      ? props.validationConfig?.birthdayFormat
      : getDatePlaceholderFor(BIRTHDAY_LOCALE).toUpperCase();

    const birthdayRegex = computed(
      () => props.validationConfig?.birthdayRegex || `[\\d*]`
    );

    const phoneNumberRegex = computed(
      () => props.validationConfig?.phoneNumberRegex || `[\\d*]`
    );

    const phoneNumberMask = computed(
      () => props.validationConfig?.phoneNumberMask
    );

    const hasBirthDateSet = computed(() =>
      Boolean(props.basicInformation.birthDate)
    );

    return {
      birthdayRegex,
      phoneNumberRegex,
      phoneNumberMask,
      getBirthdateMessage: ($obj) => {
        if (!$obj.$error) return;
        if (!$obj.validFormat)
          return props.validationMessages.birthdayError.replace(
            '{{dateFormat}}',
            datePlaceholder
          );

        if (!$obj.minAge)
          return props.validationMessages.minRegisterAgeError.replace(
            '{{minRegisterAge}}',
            String(props.minRegisterAge)
          );
      },
      dateMask: getDateMaskFor(BIRTHDAY_LOCALE),
      datePlaceholder,
      hasBirthDateSet,
    };
  },
  data() {
    return {
      email: null,
      firstName: null,
      lastName: null,
      gender: null,
      birthDate: null,
      postalCode: null,
      phone: null,
      zipError: this.validationMessages.postalCodeError,
    };
  },
  watch: {
    basicInformation: {
      handler() {
        this.copyBasicInformation();
      },
      immediate: true,
    },
  },
  mounted() {
    this.$v.$touch();
  },
  methods: {
    copyBasicInformation() {
      this.email = this.basicInformation.email;
      this.firstName = this.basicInformation.firstName;
      this.lastName = this.basicInformation.lastName;
      this.birthDate = translateFromApiDateFormat(
        this.basicInformation.birthDate,
        BIRTHDAY_LOCALE
      );
      this.gender = this.basicInformation.gender;
      this.postalCode = this.basicInformation.postalCode;
      this.phone =
        this.basicInformation.phone && stripPhone(this.basicInformation.phone);
    },
    checkZipCMSRegex(zipCode) {
      const reg =
        this.validationConfig?.zipCodeRegex !== ''
          ? new RegExp(this.validationConfig.zipCodeRegex, 'i')
          : /^\d{5}(?:-\d{4})?$/;

      if (!reg.test(zipCode)) {
        this.zipError = this.validationMessages.postalCodeError
          .replace('{{zipCodeLabel}}', '')
          .replace('{{zipCodeFormat}}', this.validationConfig.zipCodeFormat);
        return false;
      }
      return true;
    },
    checkPhoneNumber(phoneNumber) {
      if (!phoneNumber) return true;
      return checkPhone(
        phoneNumber,
        this.basicInformation.preferences.countryCode
      );
    },
    saveInfo() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('save', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        postalCode: this.postalCode,
        phone: this.phone ?? '',
        ...(!this.hasBirthDateSet && {
          birthDate: translateToApiDateFormat(this.birthDate, BIRTHDAY_LOCALE),
        }),
      });
    },
    shouldDisableInput(value: string) {
      if (!value) {
        return false;
      }

      return !this.subscribedToLoyalty;
    },
    shouldDisableBirthDateInput(value: string) {
      return this.hasBirthDateSet || this.shouldDisableInput(value);
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      firstName: {
        required,
        checkName: checkName(this.$themeConfig?.basicInformation?.nameRegExp),
      },
      lastName: {
        required,
        checkName: checkName(this.$themeConfig?.basicInformation?.nameRegExp),
      },
      birthDate: {
        validFormat: checkDate(BIRTHDAY_LOCALE),
        minAge: checkAge(this.minRegisterAge, BIRTHDAY_LOCALE),
      },
      phone: {
        checkPhone: this.checkPhoneNumber,
      },
      postalCode: {
        required: requiredIf(function () {
          return this.subscribedToLoyalty;
        }),
        checkZip: this.subscribedToLoyalty ? this.checkZipCMSRegex : true,
      },
    };
  },
});
